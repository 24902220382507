import { computed, ComputedRef } from 'vue';

interface LogoConfig {
  logoSrc: ComputedRef<string>;
  logoStyle: ComputedRef<Record<string, string>>;
}

export function useLogo(): LogoConfig {
  const currentDomain = window.location.host;

  const logoSrc = computed(() => {
    console.log(currentDomain);
    if (currentDomain !== 'topupme.kz') {
      return require('@/assets/payplay_logo.svg');
    }
    return require('@/assets/logo_template.svg');
  });

  const logoStyle = computed(() => {
    if (currentDomain !== 'topupme.kz') {
      return { width: '200px' };
    }
    return { width: '127px' };
  });

  return { logoSrc, logoStyle };
}
