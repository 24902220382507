<template>
    <div class="template-bg template-main-bg">
        <div class="template-wrap">
            <div class="template-header-wrap">
                <div class="template-header-logo">
                    <router-link
                        to="/steam/#form_pay"
                    >
                        <img
                           :src="logoSrc"
                           :style="logoStyle"
                            alt="topupme"
                        >
                    </router-link>
                </div>
                <steam-navigation />
            </div>
            <div class="template-content-wrap">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue';
import SteamNavigation from '@/components/SteamNavigation.vue';
import {useLogo} from '@/utils/dynamic_logo';

export default defineComponent({
  components: {
    SteamNavigation
  },
  setup() {
    const { logoSrc, logoStyle } = useLogo();
    return { logoSrc, logoStyle };
  }
});
</script>
