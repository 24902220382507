import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "template-bg template-main-bg" }
const _hoisted_2 = { class: "template-wrap" }
const _hoisted_3 = { class: "template-header-wrap" }
const _hoisted_4 = { class: "template-header-logo" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "template-content-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_steam_navigation = _resolveComponent("steam-navigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/steam/#form_pay" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.logoSrc,
                style: _normalizeStyle(_ctx.logoStyle),
                alt: "topupme"
              }, null, 12, _hoisted_5)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_steam_navigation)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ])
  ]))
}